// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-form {
  max-width: 400px;
}
.login-form-password {
  margin-bottom: 10px;
}
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/loginForm/index.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb","sourcesContent":[".login-form-container {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.login-form {\n  max-width: 400px;\n}\n.login-form-password {\n  margin-bottom: 10px;\n}\n.login-form-forgot {\n  float: right;\n}\n.ant-col-rtl .login-form-forgot {\n  float: left;\n}\n.login-form-button {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
