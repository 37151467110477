import { $host } from "../http";

class ActionService {
  useStorageLogActionList({ onSuccessful, onError }) {
    const getData = async () => {
      await $host
        .get("/api/action")
        .then(async (response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            throw response;
          }
        })
        .then((actions) => {
          onSuccessful(actions);
        })
        .catch((err) => {
          onError(err.message);
        });
    };

    return {
      updateActions: () => {
        getData();
      },
    };
  }

  useStorageLogActionCreate({ onSuccessful, onError }) {
    return {
      createAction: async (data) => {
        await $host
          .post("/api/action", data)
          .then((response) => response.data)
          .then((action) => {
            onSuccessful(action);
          })
          .catch((err) => {
            onError(err?.response?.data?.message);
          });
      },
    };
  }

  wrapperReadNotification({ onSuccessful, onError }) {
    return {
      saveAction: (data) => {
        $host
          .put("/api/action", data)
          .then((response) => {
            if (response.status === 200) {
              onSuccessful();
            } else {
              throw response.data;
            }
          })
          .catch((err) => {
            onError(err.message);
          });
      },
    };
  }

  wrapperGateCheck({ onSuccessful, onError }) {
    return {
      gateCheck: (data) => {
        $host
          .get(`/api/action/gate/${data}`)
          .then((response) => {
            onSuccessful(response.data);
          })
          .catch((err) => {
            onError(err.message);
          });
      },
    };
  }
}

export default new ActionService();
