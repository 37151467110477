import { Tour } from "antd";
import { memo, useEffect, useState } from "react";

export const isTourCheck = (tourKey) => {
  const tours = JSON.parse(localStorage.getItem("tours"));

    if (tours instanceof Array) {
      if (tours.includes(tourKey)) {
        return true;
      }
    }

  return false;
};

// eslint-disable-next-line react/display-name
const TourWarehouse = memo(({ open, setOpen, steps, tourKey }) => {
  const [visible, setVisible] = useState(true);

  const finish = () => {
    setOpen(false);
    let tours = JSON.parse(localStorage.getItem("tours"));

    if (!(tours instanceof Array)) {
      tours = [];
    }

    tours.push(tourKey);
    localStorage.setItem("tours", JSON.stringify(tours));
  };

  const checkTourKey = () => {
    if (isTourCheck(tourKey)) {
      setVisible(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    checkTourKey();
  }, []);

  if (visible) {
    return <Tour open={open} onClose={() => setOpen(false)} onFinish={finish} steps={steps} />;
  }

  return <></>;
});



export default TourWarehouse;
