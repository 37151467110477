import { $host } from "../http";

class AuthService {
  async login(email, password) {
    const response = await $host.post("/api/auth/login", { email, password });

    return response;
  }

  async logout() {
    const response = await $host.post("/api/auth/logout");

    return response;
  }
  async check() {
    const response = await $host.get("/api/auth/check");

    return response;
  }
}

export default new AuthService();
