import React from "react";
import LoginForm from "../components/loginForm/LoginForm";

const Auth = () => <LoginForm/>;

export default Auth;




