import { useMemo, useRef, useState } from "react";
import { getTourUniqueKey } from "../../../../utils/helpers";

const uniqueKeyTour = getTourUniqueKey("useNote");

const useNote = () => {
  const [open, setOpen] = useState(false);

  const ref = useRef(null);

  const steps = [
    {
      title: "Примечания",
      description: "Теперь в отчёте можно оставлять примечания для каждой погрузки.",
      target: () => ref.current,
    },
    {
      title: "Сохранение информации",
      description: "Введённая информация сохранится автоматически.",
      target: () => ref.current,
    },
    {
      title: "Примечания в Excel",
      description: "Примечания будут отображаться и в Excel-таблице отчёта.",
      target: () => ref.current,
    },
  ];

  return useMemo(() => [open, setOpen, steps, { ref }, uniqueKeyTour], [open]);
};

export default useNote;
