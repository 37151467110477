import { Button, Col, Input, Modal, Row, Spin } from "antd";
import React, { useState } from "react";
import timetableService from "../../services/timetableService";
import { notificationWarehouse } from "../../utils/helpers";
import { requestWrapper } from "../../utils/requestWrapper";

const ShoppingCart = ({ active, setActive, timetable }) => {
  const [shoppingText, setShoppingText] = useState(timetable?.shoppingCart || "");
  const [loading, setLoading] = useState(false);

  const close = () => {
    setShoppingText("");
    setActive(false);
  };

  const [shoppingCartSave] = requestWrapper({
    requestFunction: timetableService.edit,
    onSuccess: (data) => {
      notificationWarehouse("success", data?.message);
      close();
    },
    onError: (message) => {
      notificationWarehouse("error", message);
    },
    setLoading,
  });

  const save = async () => {
    if (timetable?.id) {
      setLoading(true);
      shoppingCartSave({ id: timetable.id, shoppingCart: shoppingText.trim() });
    } else {
      notificationWarehouse("warning", "Для шаблонной записи невозвожно добавить название магазина");
    }
  };

  return (
    <Modal
      title={"Введите название магазина"}
      open={active}
      onCancel={() => {
        setActive(false);
      }}
      footer={[
        <Spin key={"shopping-cart-footer"} spinning={loading}>
          <Row gutter={[5, 5]} justify={"space-between"}>
            <Col>
              <Button danger onClick={close}>
                Отменить
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={save}>
                Сохранить
              </Button>
            </Col>
          </Row>
        </Spin>,
      ]}
    >
      <p>Введите название магазина:</p>
      <Input
        placeholder="Введите название магазина"
        value={shoppingText}
        onChange={(e) => setShoppingText(e.target.value)}
      />
    </Modal>
  );
};

export default ShoppingCart;
