import { $host } from "../http";

class SupplierService {
  useSupplierList({ onSuccessful, onError }) {
    const getData = async () => {
      $host
        .get("/api/supplier")
        .then(async (response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            throw response;
          }
        })
        .then((supplierList) => {
          onSuccessful(supplierList);
        })
        .catch((err) => {
          onError(err.message);
        });
    };

    return {
      updateSupplierList: () => {
        getData();
      },
    };
  }

  wrapperSupplierEdit({ onSuccessful, onError }) {
    return {
      saveSupplier: (id, supplierData) => {
        $host
          .put(`/api/supplier/${id}`, supplierData)
          .then(() => {
            onSuccessful();
          })
          .catch((err) => {
            onError(err);
          });
      },
    };
  }

  wrapperSupplierDelete({ onSuccessful, onError }) {
    return {
      deleteSupplier: (id) =>
        $host
          .delete(`/api/supplier/${id}`)
          .then((res) => {
            if (res.status === 200) {
              onSuccessful();
            } else {
              throw res.data;
            }
          })
          .catch((err) => {
            onError(err.message);
          }),
    };
  }

  wrapperSupplierCreate({ onSuccessful, onError }) {
    return {
      createSupplier: (supplierData) => {
        $host
          .post("/api/supplier", supplierData)
          .then((res) => {
            if (res.status === 200) {
              onSuccessful();
            } else {
              throw res.data;
            }
          })
          .catch((err) => {
            onError(err.message);
          });
      },
    };
  }

  wrapperStorageWorkTime({ onSuccessful, onError }) {
    return {
      getStorageWorkTime: (data) => {
        $host
          .post("/api/storage_timetable/work_time", data)
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            } else {
              throw response.data;
            }
          })
          .then((response) => {
            onSuccessful(response);
          })
          .catch((err) => {
            onError(err.message);
          });
      },
    };
  }

  async editSupplier(id, supplierData) {
    return $host.put(`/api/supplier/${id}`, supplierData);
  }

  async getSuppliers(name) {
    return $host.get("/api/supplier", { params: { ...(name ? { name } : {}) } });
  }
}

export default new SupplierService();
