import { $host } from "../http";

class CarrierService {
  wrapperCall({ onSuccessful, onError }) {
    return {
      call: async (timetableId, gate, tel) => {
        $host
          .post("/api/call", { timetableId, gate, tel })
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            } else {
              throw response.data;
            }
          })
          .then((data) => {
            onSuccessful(data);
          })
          .catch((err) => {
            onError(err?.response?.data?.message);
          });
      },
    };
  }

  wrapperCallStatus({ onSuccessful, onError }) {
    return {
      callStatus: async (id) => {
        $host
          .get(`/api/call/status/${id}`)
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            } else {
              throw response.data;
            }
          })
          .then((data) => {
            onSuccessful(data);
          })
          .catch((err) => {
            onError(err?.response?.data?.message);
          });
      },
    };
  }
}

export default new CarrierService();
