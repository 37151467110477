import { $host } from "../http";

class NotificationService {
  useNotificationList({ onSuccessful, onError }) {
    const getData = async () => {
      await $host
        .get("/api/notification")
        .then(async (response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            throw response;
          }
        })
        .then((actions) => {
          onSuccessful(actions);
        })
        .catch((err) => {
          onError(err.message);
        });
    };

    return {
      updateActions: () => {
        getData();
      },
    };
  }

  useNotificationCreate({ onSuccessful, onError }) {
    const createNotification = async (data, attempt = 1) => {
        try {
          const response = await $host.post("/api/notification/create-notification", data);

          onSuccessful(response.data);
        } catch (err) {
          if (attempt <= 3) {
            await createNotification(data, attempt + 1);
          } else {
            onError(err?.response?.data?.message);
          }
        }
    }

    return {
      createNotification
    }
  }

  async saveNotification (data, attempt = 1) {
    setTimeout(async()=> {
      try {
        const response = await $host.post("/api/notification", data);

        if (attempt === 1) {
          throw response.data;
        }
      } catch (err) {
        if (attempt <= 3) {
          await this.saveNotification(data, attempt + 1);
        } else {
        }
      }
    }, 5000)

  }


  wrapperReadNotification({ onSuccessful, onError }) {
    return {
      saveAction: (data) => {
        $host
          .put("/api/notification", data)
          .then((response) => {
            if (response.status === 200) {
              onSuccessful();
            } else {
              throw response.data;
            }
          })
          .catch((err) => {
            onError(err.message);
          });
      },
    };
  }

  wrapperGateCheck({ onSuccessful, onError }) {
    return {
      gateCheck: (data) => {
        $host
          .get(`/api/notification/gate/${data}`)
          .then((response) => {
            onSuccessful(response.data);
          })
          .catch((err) => {
            onError(err.message);
          });
      },
    };
  }
}

export default new NotificationService();
