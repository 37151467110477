import { $host } from "../http";

class CarrierService {
  useStorageCarrierList({ onSuccessful, onError }) {
    const getData = async () => {
      $host
        .get("/api/carrier")
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            throw response.data;
          }
        })
        .then((carrierList) => {
          onSuccessful(carrierList);
        })
        .catch((err) => {
          onError(err.message);
        });
    };

    return {
      updateCarrierList: () => {
        getData();
      },
    };
  }

  async getCarriers(name) {
    return $host.get("/api/carrier", { params: { ...(name ? { name } : {}) } });
  }

  async getCarrier(id) {
    return $host.get(`/api/carrier/${id}`);
  }

  async createCarrier(data) {
    return $host.post("/api/carrier", data);
  }

  async updateCarrier(id, name) {
    return $host.put("/api/carrier", { id, name });
  }

  async removeCarrier(id) {
    return $host.delete(`/api/carrier/${id}`);
  }
}

export default new CarrierService();
