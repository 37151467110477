import { makeAutoObservable } from "mobx";
import authService from "../services/authService";
export default class UserStore {
  isAuth = false;
  user = {};
  isLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  setIsAuth = (bool) => {
    this.isAuth = bool;
  };

  setUser = (user) => {
    this.user = user;
  };

  setLoading = (bool) => {
    this.isLoading = bool;
  };

  async login(user, tokens = { access: { expires: "", token: "" }, refresh: { expires: "", token: "" } }) {
    try {
      localStorage.setItem("token", tokens.access.token);
      localStorage.setItem("user", JSON.stringify(user));
      this.setUser(user);
      this.setIsAuth(true);
    } catch (error) {}
  }

  async logout() {
    try {
      await authService.logout();
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      this.setUser({});
      this.setIsAuth(false);
    } catch (error) {}
  }

  self = async () => {
    this.setLoading(true);
    try {
      const response = await authService.check();

      if (response.status == 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        this.setUser({});
        this.setIsAuth(false);
      }

      this.setIsAuth(true);
      this.setUser(response.data.user);
      localStorage.setItem("user", JSON.stringify(response.data.user));
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };
}
