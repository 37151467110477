// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .chat {}

 .chat:hover {
    transform: scale(1.1);
    transition: .1s all;
    cursor: pointer;
 }

 .more-info-timetable {
    font-size: 30px;
    color: #999;
    margin: 5px 10px 0 0;
 }

 .more-info-timetable:hover {
    transform: scale(1.1);
    transition: .1s all;
    cursor: pointer;
 }

 .title {
    font-size: 16px;
 }

 .shopping-cart {
    font-size: 50px;
    color: #999;
    width: 45px;
    height: 39px;
    margin-right: 10px;
 }`, "",{"version":3,"sources":["webpack://./src/components/modals/OrderInfo.css"],"names":[],"mappings":"CAAC,OAAO;;CAEP;IACG,qBAAqB;IACrB,mBAAmB;IACnB,eAAe;CAClB;;CAEA;IACG,eAAe;IACf,WAAW;IACX,oBAAoB;CACvB;;CAEA;IACG,qBAAqB;IACrB,mBAAmB;IACnB,eAAe;CAClB;;CAEA;IACG,eAAe;CAClB;;CAEA;IACG,eAAe;IACf,WAAW;IACX,WAAW;IACX,YAAY;IACZ,kBAAkB;CACrB","sourcesContent":[" .chat {}\n\n .chat:hover {\n    transform: scale(1.1);\n    transition: .1s all;\n    cursor: pointer;\n }\n\n .more-info-timetable {\n    font-size: 30px;\n    color: #999;\n    margin: 5px 10px 0 0;\n }\n\n .more-info-timetable:hover {\n    transform: scale(1.1);\n    transition: .1s all;\n    cursor: pointer;\n }\n\n .title {\n    font-size: 16px;\n }\n\n .shopping-cart {\n    font-size: 50px;\n    color: #999;\n    width: 45px;\n    height: 39px;\n    margin-right: 10px;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
