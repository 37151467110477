import { notification, message } from "antd";
import { INDEX_VOLUME, storageDescription } from "./consts";

/**
 * Checking if the user has specific access in a particular warehouse
 * @param {Number|Number[]} storage number storage
 * @param {Object} userStorage access user
 * @param {String|String[]} checkField field to check
 * @param {Boolean=} strictEquality working only with checkField(string) and storage(array)
 * @returns
 * @example
 * userStorage = {
    "chekhov": "full",
    "nvsb": null,
    "kz": "update"
  }
 * ----------------
 * storage = 1, checkField = "full" => true
 * storage = 1, checkField = ["full", "update"] => true
 * storage = 2, checkField = ["full", "update"] => false
 * storage = 3, checkField = ["full", "update"] => true
 * 
 * storage = [1, 2], checkField = "full" => true
 * storage = [2, 3], checkField = "full" => false
 * storage = [1, 3], checkField = "full", strictEquality = true => false
 * storage = [1, 2], checkField = ["full", "update"] => true
 */
export const checkAccess = (storage, userStorage, checkField, strictEquality = false) => {
  if (storage instanceof Array) {
    const names = storage.map((el) => storageDescription[el]);

    if (strictEquality && typeof checkField === "string") {
      for (const name of names) {
        if (userStorage?.[name] !== checkField) {
          return false;
        }
      }

      return true;
    }

    for (const name of names) {
      if (checkField instanceof Array ? checkField.includes(userStorage?.[name]) : userStorage?.[name] === checkField) {
        return true;
      }
    }
  } else {
    const name = storageDescription[storage];

    if (checkField instanceof Array) {
      if (checkField.includes(userStorage?.[name])) {
        return true;
      }
    } else {
      if (userStorage?.[name] === checkField) {
        return true;
      }
    }
  }

  return false;
};

/**
 * Get an array of available warehouse
 * @param {{chekhov: string|null, nvsb: string|null, kz: string|null}} storageAccess
 * @param {(string[] | string)=} fieldAccess
 * @returns {number[]|[]}
 * @example string[] -> ['chekhov'], string -> 'full'
 */
export const getNumberWarehouse = (storageAccess, fieldAccess = null) => {
  if (!fieldAccess) {
    return Object.keys(storageAccess)
      .filter((key) => storageAccess[key] !== null)
      .map((key) => storageDescription[key]);
  } else {
    return Object.keys(storageAccess)
      .filter((key) =>
        storageAccess instanceof Array ? fieldAccess.includes(key) : storageAccess[key] === fieldAccess
      )
      .map((key) => storageDescription[key]);
  }
};

/**
 * Custom instance antd notification
 * @param {keyof import("antd/lib/notification").NotificationApi} type
 * @param {string} message
 * @param {import("antd/lib/notification").ArgsProps} options
 */
export const notificationWarehouse = (type, message = "", options = {}) => {
  if (message && typeof message === "string") {
    if (notification[type]) {
      notification[type]({ style: { top: 45 }, description: message, ...options });
    } else {
      console.log("Уведомление отменено! Причина: параметр type не корректный", { type, message, options });
    }
  } else {
    console.log("Уведомление отменено! Причина: параметр message отсутствует", { type, message, options });
  }
};

/**
 *
 * @param {("driver"|"car"|"trailer")} type
 */
export const messageWarning = (type, duration = 3) => {
  if (!type) return;

  const entity = {
    driver: "водителя",
    car: "транспортного средства",
    trailer: "прицепа",
  };

  message.warning(`Проверте актуальность данных ${entity[type]}`, duration);
};

export const getTourUniqueKey = (name) => {
  if (typeof name === "string") {
    return "tour-" + btoa(name);
  }

  return name;
};

export const messageWaitLoadFiles = () => notificationWarehouse("warning", "Подождите идет загрузка файлов");

export const isLap = window.screen.width > 786 ? true : false;

export const lsSetFilterInn = (inn) => {
  localStorage.setItem("filterInn", JSON.stringify(inn));
};

export const lsGetFilterInn = () => JSON.parse(localStorage.getItem("filterInn"));

export const duration = (value) => Math.ceil(value / 20);

export const getHours = (minHour = 8, maxHour = 19) => {
  const hours = [];

  for (let index = minHour; index <= maxHour; index++) {
    const hour = +index < 10 ? `0${index}:00:00` : `${index}:00:00`;

    hours.push({
      label: hour,
      value: hour,
      keyField: "time",
    });
  }

  return hours;
};

export const getMarks = (count, setMarks) =>
  count === 0
    ? {}
    : setMarks(
        [...Array(++count)].reduce(
          (acc, el, index) => ({ ...acc, [index * INDEX_VOLUME]: `${index * INDEX_VOLUME}` }),
          {}
        )
      );
