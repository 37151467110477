import { useMemo, useRef, useState } from "react";
import { getTourUniqueKey } from "../../../../utils/helpers";

const uniqueKeyTour = getTourUniqueKey("useInputInfo");

const useInputInfo = () => {
  const [open, setOpen] = useState(true);

  const refInputInfo2 = useRef();

  const steps = [
    {
      title: "Новый дизайн страницы",
      description: "У страницы ввода информации новый дизайн. Всё работает так же, только красивее",
      target: null,
    },
    {
      title: "Ввод основной информации",
      description: "Теперь для ввода и редактирования основной информации достаточно кликнуть на соответствующее поле.",
      target: () => refInputInfo2.current,
    },
    {
      title: "Сохранение информации",
      description: "Введённая информация сохранится автоматически.",
      target: () => refInputInfo2.current,
    },
  ];

  return useMemo(() => [open, setOpen, steps, { refInputInfo2 }, uniqueKeyTour], [open]);
};

export default useInputInfo;
