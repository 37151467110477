import { useMemo, useRef, useState } from "react";
import { getTourUniqueKey } from "../../../../utils/helpers";

const uniqueKeyTour = getTourUniqueKey("useFilterCalendar");

const useFilterCalendar = () => {
  const [open, setOpen] = useState(true);

  const ref2 = useRef(null);

  const steps = [
    {
      title: "Фильтр по юр лицам",
      description: "Теперь в календаре можно отображать только нужные вам компании.",
    },
    {
      title: "Фильтр по юр лицам",
      description: "Для этого нужно отметить нужные юр лица в фильтре.",
      target: () => ref2.current,
    },
    {
      title: "Фильтр по юр лицам",
      description: "Кнопка 'Запомнить выбор' позволит запомнить фильтрацию после перезагрузки страницы.",
      cover: (
        <img style={{ width: "200px" }} src="/assets/img/filter-calendar-step-3.png" alt="filter-calendar-step-3.png" />
      ),
      target: () => ref2.current,
    },
  ];

  return useMemo(() => [open, setOpen, steps, { ref2 }, uniqueKeyTour], [open]);
};

export default useFilterCalendar;
