import axios from "axios";
import { LOGIN_ROUTE } from "../utils/consts";
const token = localStorage.getItem("token");

const configAxios = {
  //baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: `Bearer ${token}` },
};

const configFetch = {
  headers: new Headers({
    Authorization: "Bearer " + token,
    "Content-Type": "application/json",
  }),
};

const $host = axios.create({
  withCredentials: true,
});

$host.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;

  return config;
});

$host.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status == 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.post("/api/auth/refresh-tokens", { withCredentials: true });

        localStorage.setItem("token", response.data.tokens.access.token);

        return $host.request(originalRequest);
      } catch (e) {
        console.log(e);
      }

      localStorage.removeItem("token");
      window.location.href = LOGIN_ROUTE;
    }

    throw error;
  }
);

export { $host, configAxios, configFetch };
