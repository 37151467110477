import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Context } from "..";
import useRouter from "../hooks/custom-hooks/useRouter";

function AuthRoute() {
  const { user } = useContext(Context);
  const { location } = useRouter();

  return !user.isAuth ? <Outlet /> : <Navigate replace to={location?.state?.from || "/"} />;
}

export default AuthRoute;
