import { makeAutoObservable } from "mobx";

export default class SocketStore {
  socket = null;
  isConnection = false;
  activeModal = () => {};
  connect = () => {};

  constructor() {
    makeAutoObservable(this);
  }

  setSocket = (socket) => {
    this.socket = socket;
  };

  setIsConnection = (bool) => {
    this.isConnection = bool;
  };

  setActiveModal(activeModal) {
    this.activeModal = activeModal;
  }

  setConnect(connect) {
    this.connect = connect;
  }

  /**
   * @typedef {Object} TMessageSocket
   * @property {'connection' | 'changeStatusEvent' | 'checkStatus' | 'connectionTimetable' | 'disconnectedUserTimetable' | 'removeTimetable'} event
   * @property {number} userId
   * @property {number=} timetableId
   * @property {string=} userName
   */

  /**
   *
   * @param {TMessageSocket} message
   */
  send = (message) => {
    if (this.socket) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.log("Websocket is not connection");
    }
  };

  connectionTimetable = (timetableId) => {
    this.send({ event: "connectionTimetable", timetableId });
  };

  disconnectedUserTimetable = (timetableId) => {
    if (timetableId) {
      this.send({ event: "disconnectedUserTimetable", timetableId });
    }
  };

  changeStatusTimetable = (timetableId) => {
    if (timetableId) {
      this.send({ event: "changeStatusEvent", timetableId });
    }
  };

  removeTimetable = (timetableId) => {
    if (timetableId) {
      this.send({ event: "removeTimetable", timetableId });
    }
  };
}
