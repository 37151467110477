/**
 * Send notification
 * @param {string} text message notification
 * @param {{}} data data notification
 * @param {function} func
 */
export const sendNotification = (text, data, func) => {
  const notification = new Notification("Уведомление от Склад", {
    body: text,
    icon: "/assets/img/favicon.ico",
    timestamp: 5,
    data: data,
  });

  notification.addEventListener("click", (notif) => {
    if (func) func(notif.target.data);
    notification.close();
  });

  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      notification.close();
    }
  });
};

/**
 *
 * @param {string} message
 * @param {{}} data
 */
export const backgroundNotification = async (message, data, func) => {
  if (document.visibilityState === "hidden") {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      sendNotification(message, data, func);
    } else if (Notification.permission !== "denied") {
      await Notification.requestPermission((perm) => {
        if (perm === "granted") {
          sendNotification(message, data, func);
        }
      });
    }
  }
};
