import { $host } from "../http";

class NoteService {
  async getNote(id) {
    return $host.get(`api/storage_timetable/note/${id}`);
  }

  async getNoteEvent(timetableId) {
    return $host.get(`api/storage_timetable/note/event/${timetableId}`);
  }

  async getIsNoteEvent(timetableId) {
    return $host.get(`api/storage_timetable/note/event/check/${timetableId}`);
  }

  async createNote(data) {
    return $host.post("api/storage_timetable/note", data);
  }

  async editNote(data) {
    return $host.patch("api/storage_timetable/note", data);
  }

  async deleteNote(id) {
    return $host.delete(`api/storage_timetable/note/${id}`);
  }
}

export default new NoteService();
