import { useState } from "react";
import authService from "../services/authService";
import { notificationWarehouse } from "../utils/helpers";

export const useAuth = () => {
  const [loading, setLoading] = useState(false);
  const [error] = useState(null);

  const login = async (email, password) => {
    try {
      setLoading(true);
      const response = await authService.login(email, password);

      return response.data;
    } catch (error) {
      notificationWarehouse("error", error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return { login, loading, error };
};
