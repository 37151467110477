import { Comment } from "@ant-design/compatible";
import { List, Modal, Form, Button, notification, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../..";
import noteService from "../../services/noteService";
import { requestWrapper } from "../../utils/requestWrapper";

const NoteModal = ({ active, setActive, timetable }) => {
  const [notes, setNotes] = useState([]);
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [isEditNote, setIsEditNote] = useState(false);
  const [idEditNote, setIdEditNote] = useState(null);
  const { user } = useContext(Context);

  const [getNoteEvent] = requestWrapper({
    requestFunction: noteService.getNoteEvent,
    onSuccess: (data) => {
      setNotes(
        data.map((el) => ({
          author: `${el.f} ${el.i} ${el.o}`,
          content: <p>{el.message}</p>,
          datetime: dayjs(el.createdAt).format("lll"),
          note_id: el.id,
        }))
      );
    },
    onError: () => {},
  });

  const [createNote] = requestWrapper({
    requestFunction: noteService.createNote,
    onSuccess: (data) => {
      getNoteEvent(data.note.timetableId);
      notification.success({ message: data.message });
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  const [deleteNote] = requestWrapper({
    requestFunction: noteService.deleteNote,
    onSuccess: (data) => {
      getNoteEvent(timetable.id);
      notification.success({ message: data.message });
    },
    onError: () => {},
  });

  const btnCancel = () => {
    setIsEditNote(false);
    setValue("");
  };

  const [editNote] = requestWrapper({
    requestFunction: noteService.editNote,
    onSuccess: (data) => {
      btnCancel();
      setLoading(false);
      getNoteEvent(timetable.id);
      notification.success({ message: data.message });
    },
    onError: () => {
      setLoading(false);
    },
  });

  const onEditNote = () => {
    setLoading(true);
    editNote({
      id: idEditNote,
      message: value,
    });
  };

  const onSubmit = () => {
    setLoading(true);
    createNote({
      userId: user.user.id,
      timetableId: timetable.id,
      message: value,
      type: "eventNote",
    });

    setValue("");
  };

  useEffect(() => {
    if (active && timetable?.id) {
      getNoteEvent(timetable.id);
    }

    return () => {
      setNotes([]);
    };
  }, [active]);

  return (
    <Modal
      zIndex={999999}
      title={"Заметки по событию"}
      open={active}
      onCancel={() => {
        setActive(false);
      }}
      footer={null}
    >
      {notes.length ? (
        <List
          dataSource={notes}
          itemLayout="horizontal"
          renderItem={(props) => (
            <Comment
              {...props}
              actions={[
                <span
                  key={props.note_id}
                  onClick={() => {
                    setIsEditNote(true);
                    setIdEditNote(props.note_id);
                    setValue(props.content.props.children);
                  }}
                >
                  <span className="comment-action">Изменить</span>
                </span>,
                <span onClick={() => !isEditNote && deleteNote(props.note_id)} key={"modal-note-btn-delete"}>
                  <span className="comment-action">Удалить</span>
                </span>,
              ]}
            />
          )}
        />
      ) : (
        <></>
      )}
      <Form.Item>
        <TextArea rows={3} placeholder={"Текст заметки..."} onChange={(e) => setValue(e.target.value)} value={value} />
      </Form.Item>
      <Form.Item>
        {!isEditNote ? (
          <Button htmlType="submit" loading={loading} onClick={onSubmit} type="primary">
            Добавить заметку
          </Button>
        ) : (
          <Space>
            <Button htmlType="submit" loading={loading} onClick={onEditNote} type="primary">
              Изменить заметку
            </Button>
            <Button onClick={() => btnCancel()}>Отменить</Button>
          </Space>
        )}
      </Form.Item>
    </Modal>
  );
};

export default NoteModal;
