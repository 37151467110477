import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { DefaultLayout } from "./containers/DefaultLayout";
import AuthRoute from "./hocs/AuthRoute";
import Auth from "./pages/Auth";
import { useRoutes } from "./routes";
import { Context } from ".";
import "./App.css";

const App = () => {
  const { user } = useContext(Context);
  const { isAuth, self, isLoading } = user;
  const routes = useRoutes();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      self();
    } else user.setLoading(false);
  }, []);

  if (isLoading) return <></>;

  return (
    <BrowserRouter>
      <Routes>
        {isAuth && <Route path="*" element={<DefaultLayout routes={routes} />} />}
        {!isAuth && <Route path="*" element={<Navigate to={"/login"} state={{ from: window.location.pathname }} />} />}
        <Route path={"/login"} element={<AuthRoute />}>
          <Route path="/login" element={<Auth />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default observer(App);
