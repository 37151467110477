import { $host } from "../http";

class UploadingService {
  wrapperUnloadingEdit({ onSuccessful, onError }) {
    return {
      saveUnloading: (id, data) => {
        $host
          .put(`/api/unloading/${id}`, data)
          .then(() => {
            onSuccessful();
          })
          .catch((err) => {
            onError(err?.response?.data?.message);
          });
      },
    };
  }

  wrapperUnloadingCreate({ onSuccessful, onError }) {
    return {
      createUnloading: (data) => {
        $host
          .post("/api/unloading", data)
          .then(async (res) => {
            if (res.status === 200) {
              return res.data;
            } else {
              throw res.data;
            }
          })
          .then((data) => {
            onSuccessful(data);
          })
          .catch((err) => {
            onError(err.message);
          });
      },
    };
  }

  wrapperUnloadingById({ onSuccessful, onError }) {
    return {
      getUnloading: (data) => {
        $host
          .post("/api/unloading/get", data)
          .then(async (response) => {
            if (response.status === 200) {
              return response.data;
            } else {
              throw response.data;
            }
          })
          .then((data) => {
            onSuccessful(data);
          })
          .catch((err) => {
            onError(err.message);
          });
      },
    };
  }
}

export default new UploadingService();
