const { $host } = require("../http");

class CompanyService {
  wrapperCompanyStorageList({ onSuccessful, onError }) {
    const getData = async (sklad_use) => {
      $host
        .get(`/api/company/${sklad_use}`)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            throw response;
          }
        })
        .then((companyList) => {
          onSuccessful(companyList);
        })
        .catch((err) => {
          onError(err.message);
        });
    };

    return {
      updateCompaniesList: (sklad_use) => {
        getData(sklad_use);
      },
    };
  }

  wrapperCompanyList({ onSuccessful, onError }) {
    return {
      getCompanies: (data) => {
        $host
          .post("/api/company", data)
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            } else {
              throw response.data;
            }
          })
          .then((response) => {
            onSuccessful(response);
          })
          .catch((err) => {
            onError(err.message);
          });
      },
    };
  }

  wrapperCompaniesByStorageIdList({ onSuccessful, onError }) {
    return {
      getCompaniesByStorageId: (data) => {
        $host
          .post("/api/company/by_storage_id", data)
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            } else {
              throw response.data;
            }
          })
          .then((response) => {
            onSuccessful(response);
          })
          .catch((err) => {
            onError(err.message);
          });
      },
    };
  }

  async companyStorageList(sklad_use) {
    return $host.get(`/api/company/${sklad_use}`);
  }

  async getCompaniesUser() {
    return $host.get("/api/company/user");
  }
}

export default new CompanyService();
