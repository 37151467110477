import { Navigate, Outlet } from "react-router-dom";
import { PAGE_NOT_ACCESS } from "../utils/consts";
import { checkAccess, notificationWarehouse } from "../utils/helpers";

const ProtectedRoute = ({ protection }) =>
  protection.condition ? <Outlet /> : <Navigate to={protection.redirect || PAGE_NOT_ACCESS} replace />;

export default ProtectedRoute;

/**
 * Returns filter routers by user access
 * @param {Object} user
 * @param {Array} routes
 * @returns {Array}
 */
export const filterProtectedRoutes = (user, routes) => {
  try {
    return routes.filter(
      (route) =>
        user?.type_id === 5 ||
        user?.access?.includes(route.access) ||
        route.access === 0 ||
        checkAccess([1, 2, 3], user.storage_access, ["full", "view-all"])
    );
  } catch (error) {
    notificationWarehouse("error", "Ошибка предоставления доступа");

    return routes;
  }
};
