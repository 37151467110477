import { Layout, Spin } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext, useState, Suspense } from "react";
import DefaultHeader from "./DefaultHeader";
import { Context } from "../..";
import NavBar from "../../components/navBar/NavBar";
import "./index.css";
// Task: socket
import useSocket from "../../hooks/socket/useSocket";
// Task: socket
import useSocketProblemNotification from "../../hooks/socket/useSocketProblemNotification";
import { isLap } from "../../utils/helpers";

const { Content, Sider } = Layout;

const DefaultLayout = (props) => {
  const [menuActive, setMenuActive] = useState(isLap);
  const [collapsed, setCollapsed] = useState(false);
  const { calendarStore, user, socketStore } = useContext(Context);
  const { openNotification, closeNotification, contextHolder } = useSocketProblemNotification();

  // Task: socket
  useSocket({ states: user.user.states, typeId: user.user.type_id });
  // Task: socket

  const handleCollapsed = (value) => {
    setCollapsed(value);
    calendarStore.resize();
  };

  const handleNavBar = () => {
    if (!isLap) {
      setMenuActive(false);
    }
  };

  // Task: socket
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!socketStore.isConnection) {
  //       openNotification();
  //     } else {
  //       closeNotification();
  //     }
  //   }, 500);
  // }, [socketStore.isConnection]);
  // Task: socket

  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>
      {contextHolder}
      <Sider
        className={menuActive ? "sider-left active" : "sider-left"}
        collapsible
        collapsed={collapsed}
        onCollapse={handleCollapsed}
        width={210}
        style={{}}
      >
        <NavBar handleNavBar={handleNavBar} />
      </Sider>
      <Layout className="site-layout">
        <DefaultHeader setMenuActive={setMenuActive} />
        <Content
          className="content-layout"
          style={isLap ? { marginLeft: collapsed ? "80px" : "210px" } : { marginLeft: 0 }}
        >
          <Suspense
            fallback={
              <Spin style={{ height: "100vh" }} tip="Загрузка страницы..." size="large">
                {""}
              </Spin>
            }
          >
            <div className="site-layout-background wrapper">{props.routes}</div>
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default observer(DefaultLayout);
