import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Select, Form, Space } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import "dayjs/locale/ru";
import { Context } from "../../index";
import carrierService from "../../services/carrierService";
import companyService from "../../services/companyService";
import supplierService from "../../services/supplierService";
import uploadingService from "../../services/uploadingService";
import uploadService from "../../services/uploadService";
import { getFileName } from "../../utils/consts";
import { notificationWarehouse } from "../../utils/helpers";
import { FileName } from "../../utils/Styled";
import InputDisabled from "../input/InputDisabled";

const validateMessages = {
  required: "Поле обязательно для заполнения!",
};

const UnloadingInfo = ({ active, setActive, data = {}, setData, storage, update = () => {}, startDate, endDate }) => {
  const { user } = useContext(Context);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [loadingSave, setLoadingSave] = useState(false);
  const [companyList, setCompanyList] = useState(null);
  const [supplierList, setSupplierList] = useState(null);
  const [carrierList, setCarrierList] = useState(null);
  const [isChangeUnloading, setIsChangeUnloading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [isInputGate, setIsInputGate] = useState(false);

  const [form] = Form.useForm();

  const razrgz_kz = useRef(null);

  const { uploadOneFileFunction } = uploadService.wrapperImage({
    onError: () => {
      notificationWarehouse("error", "Произошла ошибка");
    },
  });

  const { downloadFile } = uploadService.wrapperImage({
    onError: (error) => {
      notificationWarehouse("error", `Произошла ошибка: ${error}`);
    },
  });

  const { updateCompaniesList } = companyService.wrapperCompanyStorageList({
    onSuccessful: (data) => {
      setCompanyList(data);
    },
    onError: (error) => {
      notificationWarehouse("error", `Произошла ошибка: ${error}`);
    },
  });

  const { updateSupplierList } = supplierService.useSupplierList({
    onSuccessful: (data) => {
      setSupplierList(data);
    },
    onError: (error) => {
      notificationWarehouse("error", `Произошла ошибка: ${error}`);
    },
  });

  const { updateCarrierList } = carrierService.useStorageCarrierList({
    onSuccessful: (data) => {
      setCarrierList(data);
    },
    onError: (error) => {
      notificationWarehouse("error", `Произошла ошибка: ${error}`);
    },
  });

  const handleClose = () => {
    setData(null);
    setActive(false);
    setIsEdit(false);
    setEditData(null);
    setUploadFile(null);
    form.resetFields(Object.keys(form.getFieldsValue()));
    setIsChangeUnloading(false);
  };

  const { createUnloading } = uploadingService.wrapperUnloadingCreate({
    onSuccessful: (res) => {
      if (storage === 3 && razrgz_kz.current.value !== "") {
        uploadOneFileFunction(uploadFile, data.date, res.id);
      }

      notificationWarehouse("success", "Запись на разгрузку создана");
      update(storage, startDate, endDate);
      handleClose();
    },
    onError: (error) => {
      notificationWarehouse("error", `Произошла ошибка: ${error}`);
    },
  });

  const { saveUnloading } = uploadingService.wrapperUnloadingEdit({
    onSuccessful: () => {
      if (storage === 3) {
        uploadOneFileFunction(uploadFile, data.date, data.id);
      }

      setLoadingSave(false);
      notificationWarehouse("success", "Запись на разгрузку отредактирована");
      update(storage, startDate, endDate);
      handleClose();
    },
    onError: (error) => {
      notificationWarehouse("error", `Произошла ошибка: ${error}`);
    },
  });

  const format = "HH:mm";

  const onFinish = () => {
    createUnloading({
      ...editData,
      date: data?.date,
      sklad_use: storage,
    });
  };

  const BtnMarkingPassageCars = () => (
    <Row gutter={[5, 5]}>
      {[5, 54].includes(user.user.type_id) ? (
        <>
          {!data?.arrivalDate && !data?.departureDate && data?.actionId === 18 ? (
            <Col>
              <Button
                onClick={() =>
                  saveUnloading(data?.id, { arrivalDate: dayjs().format("YYYY-MM-DD HH:mm:ss"), actionId: 9 })
                }
              >
                Машина проехала
              </Button>
            </Col>
          ) : data?.arrivalDate && !data?.departureDate ? (
            <Col>
              <Button
                onClick={() =>
                  saveUnloading(data?.id, { departureDate: dayjs().format("YYYY-MM-DD HH:mm:ss"), actionId: 10 })
                }
              >
                Машина выехала
              </Button>
            </Col>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {[5, 37].includes(user.user.type_id) && data?.actionId === 13 ? (
        <>
          <Col>
            <Button
              onClick={() => {
                setIsInputGate(true);
              }}
            >
              Ввести номер ворот
            </Button>
          </Col>
        </>
      ) : (
        <></>
      )}
      {[5, 54].includes(user.user.type_id) && data?.actionId === 1 ? (
        <>
          <Col>
            <Button
              onClick={() => {
                saveUnloading(data?.id, { actionId: 13 });
              }}
            >
              Машина приехала
            </Button>
          </Col>
        </>
      ) : (
        <></>
      )}
    </Row>
  );

  useEffect(() => {
    if (active) {
      updateCompaniesList(storage);
      updateCarrierList();
    }

    return () => {
      setEditData(null);
      setIsChangeUnloading(false);
      setIsEdit(false);
      setIsInputGate(false);
      form.resetFields();
    };
  }, [active]);

  return (
    <Modal
      zIndex={10000}
      open={active}
      onCancel={() => {
        handleClose();
      }}
      title={"Запись на разгрузку"}
      footer={
        !data?.id
          ? [
              <Row key={"btnSaveClose"} justify={"space-between"}>
                <div></div>
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Сохранить
                  </Button>
                </Col>
              </Row>,
            ]
          : [
              <Row key={"btnEditCancel"} justify={"space-between"}>
                {!isEdit ? (
                  <>
                    {!isChangeUnloading && !isInputGate ? (
                      <>
                        <Col>
                          <Button
                            key="back"
                            danger
                            onClick={() => {
                              saveUnloading(data?.id, { status: "cancel" });
                            }}
                          >
                            Отменить
                          </Button>
                        </Col>
                        <Col>
                          <Space>
                            {![37, 54].includes(user.user.type_id) && (
                              <Button
                                onClick={() => {
                                  setIsChangeUnloading(true);
                                  setEditData({ ...data });
                                }}
                                disabled={dayjs(dayjs().format("YYYY-MM-DD")) > dayjs(data?.date)}
                              >
                                Изменить
                              </Button>
                            )}
                            <BtnMarkingPassageCars />
                          </Space>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => {
                            setIsChangeUnloading(false);
                            setIsInputGate(false);
                          }}
                        >
                          Назад
                        </Button>
                        <Button
                          loading={loadingSave}
                          onClick={() => {
                            setLoadingSave(true);
                            saveUnloading(data.id, editData);
                          }}
                        >
                          Сохранить
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <Col>
                    <Button
                      onClick={() => {
                        setIsEdit(false);
                        setEditData(null);
                      }}
                    >
                      Назад
                    </Button>
                  </Col>
                )}
              </Row>,
            ]
      }
    >
      {isEdit || !data?.id ? (
        <Form
          onFinish={onFinish}
          form={form}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 15,
          }}
          layout="horizontal"
          validateMessages={validateMessages}
        >
          <Form.Item name={"date"} label={"Запись на:"} valuePropName={"date"}>
            <InputDisabled
              style={{ width: "100%" }}
              value={data?.id ? (editData?.date !== null ? editData?.date : null) : data?.date}
            />
          </Form.Item>
          <Form.Item name={"inn"} label={"Организация:"} rules={[{ required: true }]}>
            <Select
              dropdownStyle={{ zIndex: 10001 }}
              value={editData?.inn ? editData?.inn : null}
              style={{ width: "100%" }}
              onFocus={() => {
                updateCompaniesList(storage);
              }}
              onChange={(value) => {
                setEditData({ ...editData, inn: value });
              }}
            >
              <Select.Option value={"1"}>Все организации</Select.Option>
              {companyList?.map((el) => (
                <Select.Option key={el.id} value={el?.inn}>
                  {el?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name={"type_razgrz"} label={"Тип:"} rules={[{ required: true }]}>
            <Select
              dropdownStyle={{ zIndex: 10001 }}
              value={editData?.type_razgrz ? editData?.type_razgrz : null}
              style={{ width: "100%" }}
              onChange={(value) => {
                setEditData({ ...editData, type_razgrz: value });
              }}
            >
              <Select.Option value={"vnech"}>Внешняя</Select.Option>
              <Select.Option value={"vnytr"}>Внутренняя</Select.Option>
            </Select>
          </Form.Item>

          {editData?.type_razgrz === "vnech" && storage !== 3 && (
            <>
              <Form.Item name={"delivery_type"} label={"Способ доставки:"} rules={[{ required: true }]}>
                <Select
                  dropdownStyle={{ zIndex: 10001 }}
                  value={editData?.delivery_type ? editData?.delivery_type : null}
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    setEditData({ ...editData, delivery_type: value });
                  }}
                >
                  <Select.Option value={"by_trail"}>ЖД</Select.Option>
                  <Select.Option value={"by_sea"}>Морской</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name={"delivery_time"}
                label={"Время прибытия:"}
                rules={[
                  {
                    validator: () => {
                      if (editData.delivery_time?.match(/^(([0-1][0-9])|(2[0-4])):[0-5][0-9]$/gm)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error("Не верный формат времени!"));
                      }
                    },
                  },
                ]}
              >
                <Input
                  placeholder={"Введите время (HH:mm)"}
                  maxLength={5}
                  style={{ width: "100%" }}
                  defaultValue={editData?.delivery_time?.length > 0 ? dayjs(editData?.delivery_time, format) : null}
                  onChange={(e) => {
                    setEditData({ ...editData, delivery_time: e.target.value });
                  }}
                  value={editData?.delivery_time}
                  allowClear
                />
              </Form.Item>
            </>
          )}
          <Form.Item name={"carrier"} label={"Перевозчик:"} rules={[{ required: true }]}>
            <Select
              dropdownStyle={{ zIndex: 10001 }}
              value={editData?.carrier ? editData?.carrier : null}
              style={{ width: "100%" }}
              showSearch
              onFocus={() => updateCarrierList()}
              optionFilterProp="children"
              filterOption={(input, option) => option?.children?.toLowerCase().includes(input.toLowerCase())}
              onChange={(value) => {
                setEditData({ ...editData, carrier: value });
              }}
            >
              {carrierList?.map((el) => (
                <Select.Option key={el.id} value={el?.id}>
                  {el?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {editData?.type_razgrz === "vnytr" && (
            <Form.Item name={"supplier"} label={"Поставщик:"} rules={[{ required: true }]}>
              <Select
                dropdownStyle={{ zIndex: 10001 }}
                value={editData?.supplier ? editData?.supplier : null}
                style={{ width: "100%" }}
                showSearch
                onFocus={() => updateSupplierList()}
                optionFilterProp="children"
                filterOption={(input, option) => option?.children?.toLowerCase().includes(input.toLowerCase())}
                onChange={(value) => {
                  setEditData({ ...editData, supplier: value });
                }}
              >
                {supplierList?.map((el) => (
                  <Select.Option key={el.id} value={el?.id}>
                    {el?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {editData?.type_razgrz === "vnech" && (
            <Form.Item name={"razgrz_info"} label={"Номер контейнера:"} rules={[{ required: true }]}>
              <Input
                value={editData?.razgrz_info ? editData?.razgrz_info : null}
                style={{ width: "100%" }}
                onChange={(event) => {
                  setEditData({ ...editData, razgrz_info: event.target.value });
                }}
              />
            </Form.Item>
          )}

          <FileName>
            {uploadFile?.registrationCheck !== null
              ? uploadFile?.registrationCheck?.data?.name
              : getFileName("razrgz_kz", data)}
          </FileName>
          <input
            style={{
              opacity: 0,
              height: 0,
              width: 0,
              lineHeight: 0,
              overflow: "hidden",
              padding: 0,
              margin: 0,
            }}
            ref={razrgz_kz}
            type={"file"}
            onChange={(event) => {
              if (event.target.files.length !== 0) {
                setUploadFile({
                  ...uploadFile,
                  registrationCheck: {
                    data: event.target.files[0],
                    type: "razrgz_kz",
                  },
                });
              }
            }}
          />
          {storage === 3 && (
            <Form.Item name={"upload_file"} label={"Накладная:"}>
              <Button
                style={{ marginTop: "4%" }}
                onClick={() => {
                  razrgz_kz.current.click();
                }}
              >
                Загрузить файл
              </Button>
            </Form.Item>
          )}
        </Form>
      ) : (
        <Form onFinish={onFinish} form={form} layout="vertical">
          <Form.Item label={"Дата разгрузки:"}>
            <InputDisabled value={dayjs(data?.date).format("DD-MM-YYYY")} />
          </Form.Item>

          {isChangeUnloading ? (
            <Form.Item name={"inn"} label={"Организация:"} rules={[{ required: true }]} initialValue={editData?.inn}>
              <Select
                dropdownStyle={{ zIndex: 10001 }}
                value={editData?.inn ? editData?.inn : null}
                style={{ width: "100%" }}
                onChange={(value) => {
                  setEditData({ ...editData, inn: value });
                }}
              >
                <Select.Option value={"1"}>Все организации</Select.Option>
                {companyList?.map((el) => (
                  <Select.Option key={el.id} value={el?.inn}>
                    {el?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item label={"Организация:"}>
              <InputDisabled value={data?.companyName} />
            </Form.Item>
          )}
          <Form.Item label={"Тип:"}>
            <InputDisabled value={data?.typeName} />
          </Form.Item>
          {data?.type_razgrz === "vnech" ? (
            <>
              {storage !== 3 && (
                <>
                  {isChangeUnloading ? (
                    <Form.Item
                      name={"delivery_type"}
                      label={"Способ доставки:"}
                      rules={[{ required: true }]}
                      initialValue={editData?.delivery_type}
                    >
                      <Select
                        dropdownStyle={{ zIndex: 10001 }}
                        value={editData?.delivery_type ? editData?.delivery_type : null}
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          setEditData({ ...editData, delivery_type: value });
                        }}
                      >
                        <Select.Option value={"by_trail"}>ЖД</Select.Option>
                        <Select.Option value={"by_sea"}>Морской</Select.Option>
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item label={"Способ доставки:"}>
                      <InputDisabled value={data?.deliveryName} />
                    </Form.Item>
                  )}
                </>
              )}

              {storage !== 3 && (
                <>
                  {isChangeUnloading ? (
                    <Form.Item
                      name={"delivery_time"}
                      label={"Время прибытия:"}
                      rules={[
                        { required: true },
                        {
                          validator: () => {
                            if (editData.delivery_time?.match(/^(([0-1][0-9])|(2[0-4])):[0-5][0-9]$/gm)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(new Error("Не верный формат времени!"));
                            }
                          },
                        },
                      ]}
                      initialValue={editData?.delivery_time}
                    >
                      <Input
                        placeholder={"Введите время (HH:mm)"}
                        maxLength={5}
                        style={{ width: "100%" }}
                        defaultValue={
                          editData?.delivery_time?.length > 0 ? dayjs(editData?.delivery_time, format) : null
                        }
                        onChange={(e) => {
                          setEditData({ ...editData, delivery_time: e.target.value });
                        }}
                        value={editData?.delivery_time}
                        allowClear
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item label={"Время доставки:"}>
                      <InputDisabled value={data?.delivery_time} />
                    </Form.Item>
                  )}
                </>
              )}

              {isChangeUnloading ? (
                <Form.Item
                  name={"carrier"}
                  label={"Перевозчик:"}
                  rules={[{ required: true }]}
                  initialValue={editData?.carrier}
                >
                  <Select
                    dropdownStyle={{ zIndex: 10001 }}
                    value={editData?.carrier ? editData?.carrier : null}
                    style={{ width: "100%" }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option?.children?.toLowerCase().includes(input.toLowerCase())}
                    onChange={(value) => {
                      setEditData({ ...editData, carrier: value });
                    }}
                  >
                    {carrierList?.map((el) => (
                      <Select.Option key={el.id} value={el?.id}>
                        {el?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item label={"Перевозчик:"}>
                  <InputDisabled value={data?.carrierName} />
                </Form.Item>
              )}
              {isChangeUnloading ? (
                <Form.Item
                  name={"razgrz_info"}
                  label={"Номер контейнера:"}
                  rules={[{ required: true }]}
                  initialValue={editData?.razgrz_info}
                >
                  <Input
                    value={editData?.razgrz_info ? editData?.razgrz_info : null}
                    style={{ width: "100%" }}
                    onChange={(event) => {
                      setEditData({ ...editData, razgrz_info: event.target.value });
                    }}
                  />
                </Form.Item>
              ) : (
                <Form.Item label={"Номер контейнера:"}>
                  <InputDisabled value={data?.razgrz_info} />
                </Form.Item>
              )}
              {storage === 3 && (
                <>
                  <Row> Накладная:</Row>
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      if (data?.fileInfo?.id) {
                        downloadFile(data.fileInfo.id, data.fileInfo.name);
                      } else {
                        notificationWarehouse("error", "Накладная не прикреплена");
                      }
                    }}
                  >
                    Скачать
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              {isChangeUnloading ? (
                <Form.Item
                  name={"carrier"}
                  label={"Перевозчик:"}
                  rules={[{ required: true }]}
                  initialValue={editData?.carrier}
                >
                  <Select
                    dropdownStyle={{ zIndex: 10001 }}
                    value={editData?.carrier ? editData?.carrier : null}
                    style={{ width: "100%" }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option?.children?.toLowerCase().includes(input.toLowerCase())}
                    onChange={(value) => {
                      setEditData({ ...editData, carrier: value });
                    }}
                  >
                    {carrierList?.map((el) => (
                      <Select.Option key={el.id} value={el?.id}>
                        {el?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item label={"Перевозчик:"}>
                  <InputDisabled value={data?.carrierName} />
                </Form.Item>
              )}

              {isChangeUnloading ? (
                <Form.Item
                  name={"supplier"}
                  label={"Поставщик:"}
                  rules={[{ required: true }]}
                  initialValue={editData?.supplier}
                >
                  <Select
                    dropdownStyle={{ zIndex: 10001 }}
                    value={editData?.supplier ? editData?.supplier : null}
                    style={{ width: "100%" }}
                    showSearch
                    onFocus={() => updateSupplierList()}
                    optionFilterProp="children"
                    filterOption={(input, option) => option?.children?.toLowerCase().includes(input.toLowerCase())}
                    onChange={(value) => {
                      setEditData({ ...editData, supplier: value });
                    }}
                  >
                    {supplierList?.map((el) => (
                      <Select.Option key={el.id} value={el?.id}>
                        {el?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item label={"Поставщик:"}>
                  <InputDisabled value={data?.supplierName} />
                </Form.Item>
              )}

              {storage === 3 && (
                <>
                  <Row> Накладная:</Row>
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      if (data?.fileInfo?.id) {
                        downloadFile(data.fileInfo.id, data.fileInfo.name);
                      } else {
                        notificationWarehouse("error", "Накладная не прикреплена");
                      }
                    }}
                  >
                    Скачать
                  </Button>
                </>
              )}
            </>
          )}
          <Form.Item label={"Время заезда:"}>
            <InputDisabled value={data?.arrivalDate ? dayjs(data?.arrivalDate).format("YYYY-MM-DD HH:mm:ss") : ""} />
          </Form.Item>
          <Form.Item label={"Время выезда:"}>
            <InputDisabled
              value={data?.departureDate ? dayjs(data?.departureDate).format("YYYY-MM-DD HH:mm:ss") : ""}
            />
          </Form.Item>
          <Form.Item label={"Статус разгрузки:"}>
            <InputDisabled value={data?.actionName} />
          </Form.Item>
          {isInputGate ? (
            <Form.Item label={"Номер ворот:"} name={"gate"} initialValue={editData?.gate}>
              <Input
                onChange={(event) => {
                  setEditData({ ...editData, gate: event.target.value, actionId: 18 });
                }}
              />
            </Form.Item>
          ) : (
            <Form.Item label={"Номер ворот:"}>
              <InputDisabled value={data?.gate} />
            </Form.Item>
          )}
        </Form>
      )}
    </Modal>
  );
};

export default UnloadingInfo;
