import { useMemo, useRef, useState } from "react";
import { getTourUniqueKey } from "../../../../utils/helpers";

const uniqueKeyTour = getTourUniqueKey("useChangeTime");

const useChangeTime = () => {
  const [open, setOpen] = useState(true);

  const ref = useRef(null);

  const steps = [
    {
      title: "Перенос погрузки",
      description: "Каждую погрузку можно удобно переносить на другое время.",
      target: () => ref.current,
    },
    {
      title: "Перенос погрузки",
      description: "Для это нужно выбрать дату, на которую будет перенесена погрузка.",
      cover: <img style={{ width: "400px" }} alt="change-time-step-3.png" src="/assets/img/change-time-step-2.png" />,
    },
    {
      title: "Перенос погрузки",
      description:
        "Затем нужно выбрать время начала перенесённой погрузки. Варианты времени будут предлагаться исходя из свободных ячеек, куда может быть перенесена запись, учитывая длительность погрузки.",
      cover: <img style={{ width: "400px" }} alt="change-time-step-3.png" src="/assets/img/change-time-step-3.png" />,
    },
  ];

  return useMemo(() => [open, setOpen, steps, { ref }, uniqueKeyTour], [open]);
};

export default useChangeTime;
