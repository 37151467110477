import { useMemo } from "react";
import { useParams, useLocation, useNavigate, useMatch } from "react-router-dom";

const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  // const match = useMatch();

  /**
   * Convert location.search in object
   * @param {string} search
   * @returns {TQuery} obj
   */
  const queryString = (search) => {
    const obj = {};
    const arr = search.replace("?", "").split("&");

    arr.forEach((element) => {
      const [key, value] = element.split("=");

      obj[key] = value;
    });

    return obj;
  };

  return useMemo(
    () => ({
      push: (path) => navigate(path),
      replace: (path) => navigate(path, { replace: true }),
      pathname: location.pathname,
      query: {
        ...queryString(location.search),
        ...params,
      },
      // match,
      location,
      navigate,
    }),
    [
      params,
      // match,
      location,
      navigate,
    ]
  );
};

export default useRouter;
