import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Form, Input, Button, Checkbox } from "antd";
import "./index.css";
import Layout from "antd/lib/layout/layout";
import React, { useContext, useState } from "react";
import { Context } from "../..";
import { useAuth } from "../../hooks/auth.hook";
import useRouter from "../../hooks/custom-hooks/useRouter";
import { STORAGE_CHEKHOV_ROUTE } from "../../utils/consts";
import { notificationWarehouse } from "../../utils/helpers";

const LoginForm = () => {
  const [form, setForm] = useState({
    email: null,
    password: null,
  });
  const { replace, location } = useRouter();
  const { login, loading } = useAuth();

  const { user } = useContext(Context);

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const loginHandler = async () => {
    const data = await login(form.email, form.password);

    if (!data) {
      notificationWarehouse("error", "Проверьте введенные данные!");
    } else {
      user.login(data.user, data.tokens);
      if (location.state?.from) {
        replace(location.state.from);
      } else replace(STORAGE_CHEKHOV_ROUTE);
    }
  };

  return (
    <Layout className="login-form-container">
      <img alt={""} src={"/assets/img/logo.png"} />
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
      >
        <Form.Item
          name="Имя пользователя"
          rules={[
            {
              type: "email",
              message: "Введен не верный формат почты",
            },
            {
              required: true,
              message: "Введите адрес электронной почты",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
            name="email"
            value={form.email}
            onChange={changeHandler}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              min: 6,
              message: "Минимум 6 символов",
            },
            {
              required: true,
              message: "Введите пароль",
            },
          ]}
          className={"login-form-password"}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Пароль"
            name="password"
            value={form.password}
            onChange={changeHandler}
          />
        </Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Запомнить</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            onClick={form.email && form.password && loginHandler}
            disabled={loading}
            loading={loading}
          >
            Войти
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default LoginForm;
