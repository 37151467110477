import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import timetableService from "../../services/timetableService";
import TourWarehouse, { components } from "../../tour";
import { notificationWarehouse } from "../../utils/helpers";
import { requestWrapper } from "../../utils/requestWrapper";

const range = (start, end) => {
  const result = [];

  for (let i = start; i < end; i++) {
    result.push(i);
  }

  return result;
};

const warehouseKey = {
  1: { start: 8, end: 19 },
  2: { start: 7, end: 17 },
  3: { start: 9, end: 17 },
};

const ChangeTimeDatePicker = ({ data, week, warehouse, update }) => {
  const [day, setDay] = useState(null);
  const [time, setTime] = useState(null);
  const [disabledTime, setDisabledTime] = useState([]);
  const [visible, setVisible] = useState(false);

  const datePickerTimeRef = useRef();

  const [tour, setTour, tourSteps, tourRef, tourKey] = components.ChangeTime.useChangeTime();

  const disabledDate = (current) => current && current < dayjs().subtract(1, "day").endOf("day");

  const disabledDateTime = () => ({
    disabledHours: () => [
      ...range(0, warehouseKey[warehouse].start),
      ...disabledTime,
      ...range(warehouseKey[warehouse].end + 1, 24),
    ],
  });

  const checkBusyTime = (timeDisabledArray = []) => {
    const { start, end } = warehouseKey[warehouse];

    for (let i = start; i < end; i++) {
      if (!timeDisabledArray.includes(i)) {
        return false;
      }
    }

    return true;
  };

  const getBusyTime = async (date) => {
    const arrayTime = await timetableService.getBusyTime({
      from: data.startTime,
      inn: data?.inn,
      week,
      date,
      eventDuration: data.eventDuration,
    });

    if (checkBusyTime(arrayTime.data)) {
      setDay(null);
      notificationWarehouse("warning", "На эту дату нет свободного времени для переноса погрузки");
    }

    setDisabledTime(arrayTime.data);
  };

  const [updateTimetable] = requestWrapper({
    requestFunction: timetableService.updateTimetable,
    onSuccess: () => {
      notificationWarehouse("success", "Запись перенесена");
      update();
    },
    onError: () => {
      notificationWarehouse("error", "Произошла ошибка при переносе записи");
    },
  });

  const [changeDate, setChangeDate] = useState(null);

  const moveTime = async () => {
    if (!data.statusIds?.includes("14")) {
      const newData = {
        date: data.startTime,
        event_duration: data.eventDuration,
        inn: data.inn,
        sklad_use: warehouse,
        state_id: data.stateId,
        status: "cancel",
        weight: data.weight,
        record_volume: data.record_volume,
        type: "pogrz",
        week: week,
        template: true,
      };

      if (data.status === "template") {
        timetableService.createTimetable(newData).then(() => {
          timetableService
            .createTimetable({
              ...newData,
              date: dayjs(changeDate.changeDate).set("minutes", 0).set("seconds", 0).format("YYYY-MM-DD HH:mm:ss"),
              ...(data.status === "agreed" ? { status: "agreed" } : { status: "entryInfo" }),
              dayWeek: dayjs(changeDate.changeDate).day(),
              actionId: 17,
            })
            .then(() => {
              update();
            });
        });
      } else {
        updateTimetable({
          id: data?.id,
          inn: data?.inn,
          week,
          date: dayjs(changeDate.changeDate).set("minutes", 0).set("seconds", 0).format("YYYY-MM-DD HH:mm:ss"),
          event_duration: data.eventDuration,
        });
      }
    }
  };

  useEffect(() => {
    setDay(null);
    setTime(null);
    setVisible(false);

    return () => {
      setChangeDate(null);
    };
  }, [data]);

  if (!visible) {
    return (
      <>
        <TourWarehouse open={tour} setOpen={setTour} steps={tourSteps} tourKey={tourKey} />
        <Button type="primary" onClick={() => setVisible(true)} ref={tourRef.ref}>
          Перенести
        </Button>
      </>
    );
  }

  return (
    <>
      <DatePicker
        disabledDate={disabledDate}
        locale={locale}
        popupStyle={{ zIndex: 10001 }}
        style={{
          borderRadius: "6px 0px 0px 6px",
          borderRight: 0,
          width: 100,
        }}
        value={day ? dayjs(day) : day}
        suffixIcon={false}
        placement="topRight"
        changeOnBlur={true}
        format="YYYY-MM-DD"
        onChange={(changedTime, dateString) => {
          if (changedTime < dayjs() && !changeDate) {
            notificationWarehouse("warning", "На данное время невозможно перенести запись");
          } else {
            if (dateString.length) {
              setDay(dateString);
              setTime(null);
              setChangeDate({ changeDate: dateString });
              getBusyTime(dateString);
            } else {
              setDay(null);
              setTime(null);
              setChangeDate({ changeDate: null });
            }
          }
        }}
      />
      <DatePicker
        ref={datePickerTimeRef}
        disabledTime={disabledDateTime}
        popupStyle={{ zIndex: 10001 }}
        locale={locale}
        style={{
          borderRadius: 0,
          width: 80,
          borderLeft: 0,
          borderRight: 0,
        }}
        value={time ? dayjs(time, "HH:mm:ss") : time}
        suffixIcon={false}
        disabled={!day}
        placeholder="Время"
        placement="topRight"
        changeOnBlur={true}
        format="HH:00:00"
        showNow={false}
        showTime={{ format: "HH", hideDisabledOptions: true }}
        picker="time"
        onSelect={(value) => {
          const dateString = value.format("HH:00:00");

          setTime(dateString);
          setChangeDate({ changeDate: [day, dateString].join(" ") });
          datePickerTimeRef.current.blur();
        }}
      />
      <Button
        danger
        type="primary"
        style={{
          borderRadius: 0,
        }}
        onClick={() => setVisible(false)}
      >
        <CloseOutlined />
      </Button>
      <Button
        type="primary"
        style={{
          borderRadius: "0px 6px 6px 0px",
          marginLeft: 0,
        }}
        disabled={!day || !time}
        onClick={() => moveTime()}
      >
        <CheckOutlined />
      </Button>
    </>
  );
};

export default ChangeTimeDatePicker;
