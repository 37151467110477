import { notification } from "antd";

const useNotificationWarehouse = () => {
  const [api, contextHolder] = notification.useNotification({
    top: 64,
  });

  /**
   *
   * @param {keyof import("antd/lib/notification").NotificationApi} type
   * @param {import("antd/lib/notification").ArgsProps} options
   */
  const openNotificationWithIcon = (type, options = {}) => {
    api[type]({
      message: "Уведомление",
      duration: 0,
      ...options,
    });
  };

  return {
    api,
    contextHolder,
    openNotificationWithIcon,
  };
};

export default useNotificationWarehouse;
