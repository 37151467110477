import { makeAutoObservable } from "mobx";

export default class NotificationStore {
  notifications = [];
  handleClickNotification = null;

  constructor() {
    makeAutoObservable(this);
  }

  getNotification() {
    return this.notifications;
  }

  setNotification(notify = []) {
    this.notifications = notify;
  }

  addNotification(notify = []) {
    this.notifications = [...notify, ...this.notifications];
  }

  removeNotification(id) {
    this.notifications = this.notifications.filter((notify) => notify.storage_timetable_id !== id);
  }
}
