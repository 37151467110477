import { useEffect, useContext } from "react";
import { Context } from "../../index";
import { backgroundNotification } from "../../utils/background-notification/backgroundNotification";
import { notificationWarehouse } from "../../utils/helpers";

/**
 *
 * @param {Object} payloadConnection
 * @param {Array} deps
 * @returns
 */
const useSocket = (payloadConnection = {}, deps = []) => {
  /** @type {WebSocket} */
  let socket = null;

  const { user, socketStore, calendarStore, notificationStore } = useContext(Context);

  const updateCalendar = () => {
    calendarStore.calendar.getApi().gotoDate(calendarStore.calendar.getApi().getDate());
    socketStore?.activeModal(false);
  };

  /**
   * Reconnected socket
   * @param {number} timeout ms
   */
  const reconnect = (timeout = 5000) => {
    setTimeout(() => {
      // eslint-disable-next-line no-use-before-define
      connect();
    }, timeout);
  };

  const connect = () => {
    if (!user.isAuth) return;

    const { host } = window.location;

    const PORT = process.env.CLIENT_WEBSOCKET_PORT || 5000;
    const protocolPrefix = window.location.protocol === "https:" ? "wss:" : "ws:";

    socket = new WebSocket(
      `${protocolPrefix}//${host.split(":")[0]}${protocolPrefix === "wss:" ? "/ws/" : `:${PORT}/`}`
    );

    socket.onopen = () => {
      const infoConnection = {
        userId: user.user.id,
        userName: `${user.user.f} ${user.user.i} ${user.user.o}`,
        event: "connection",
        ...payloadConnection,
      };

      socket.send(JSON.stringify(infoConnection));

      socketStore.setSocket(socket);
      socketStore.setIsConnection(true);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      console.log("message socket = ", JSON.parse(event.data));

      switch (data.event) {
        case "changeStatusEvent":
          notificationWarehouse("info", data.message, { duration: 5 });
          updateCalendar();
          break;
        case "connectionTimetable":
          notificationWarehouse("info", data.message);
          break;
        case "disconnectedUserTimetable":
          notificationWarehouse("info", data.message);
          break;
        case "removeTimetable":
          notificationWarehouse("info", data.message);
          updateCalendar();
          break;
        case "createNotification":
          notificationStore.addNotification([data.notification]);
          backgroundNotification(
            data.notification.notificationText,
            { ...data.notification },
            notificationStore.handleClickNotification
          );

          notificationStore.runListenerNotification(data.notification);
          break;
        case "deleteNotification":
          notificationStore.removeNotification(data.notification.id);
          break;
        default:
          break;
      }
    };

    socket.onerror = (event) => {
      console.log(event.data);
      notificationWarehouse("error", event.data?.message);
      socketStore.setIsConnection(false);
      socket.close();
    };

    socket.onclose = (event) => {
      console.log("socket close");
      notificationWarehouse("close", event.data?.message);
      socketStore.setIsConnection(false);

      // reconnect(1000);
    };
  };

  socketStore.setConnect(connect);

  useEffect(() => {
    connect();

    return () => {
      socket?.close();
    };
  }, [...deps, user.isAuth]);
};

export default useSocket;
