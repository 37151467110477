/**
 *
 * @param {{ requestFunction: Promise<any>, onSuccess?: (responseData, requestData) => {}, onError?: (message: string) => {}, setLoading?: () => {}  }} param0
 */
export const requestWrapper = ({
  requestFunction,
  onSuccess = () => {},
  onError = () => {},
  setLoading = () => {},
}) => {
  const request = (...args) => {
    requestFunction(...args)
      .then((response) => {
        onSuccess(response.data, ...args);
      })
      .catch((error) => {
        onError(error?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return [request];
};
