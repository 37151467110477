import { Col } from "antd";
import styled from "styled-components";

export const Div = styled.div`
  font-size: 1em;
  height: 2.2em;
  background: #fafafa;
  border: 1px solid lightgray;
  border-radius: 6px;
  padding: 0.25em 1em;
  margin: 0.5em 1em;
`;

export const DivCard = styled.div`
  font-size: 1em;
  height: 2.2em;
  background: #fafafa;
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 0.25em 1em;
  margin: 0;
`;

export const A = styled.a`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const HeaderForDiv = styled.div`
  font-weight: 600;
  margin: 0 1em;
`;

export const DivColor = styled.div`
  margin-left: 10em;
  color: white;
  font-weight: bold;
  text-shadow: 0 0 2px black;
`;

export const DivColorNew = styled.div`
  margin-left: 10em;
  color: black;
  font-weight: 600;
`;

export const FileName = styled.div`
  white-space: nowrap;
  overflow: auto;
  width: 300px;
`;

export const FileCol = styled(Col)`
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
`;
