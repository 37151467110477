import { notification } from "antd";
import { useContext } from "react";
import { Context } from "../..";
import useRouter from "../custom-hooks/useRouter";
import "./index.css";

const useSocketProblemNotification = () => {
  const { socketStore } = useContext(Context);
  const { pathname } = useRouter();
  const [api, contextHolder] = notification.useNotification({
    top: 65,
  });

  const openNotification = () => {
    const key = `open${Date.now()}`;

    api.open({
      message: <strong style={{ fontWeight: 600 }}>Ошибка соединения с сервером</strong>,
      description: (
        <>
          <span>
            Пожалуйста, нажмите{" "}
            <a
              href="#"
              onClick={() => {
                socketStore.connect();
              }}
            >
              Переподключиться
            </a>{" "}
            или обновите страницу.
          </span>
          {["/loading_registration", "/show_record"].filter((el) => pathname.match(el)).length ? (
            <p>Данные могут неверно сохраниться</p>
          ) : (
            ""
          )}
        </>
      ),
      className: "socket-problem-notification",
      key,
      closeIcon: null,
      duration: 1_000_000,
      style: { padding: "8px", border: "2px solid red" },
    });
  };

  const closeNotification = () => api.destroy();

  return { api, contextHolder, openNotification, closeNotification };
};

export default useSocketProblemNotification;
