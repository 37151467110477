import { BellOutlined, MenuOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Badge, Button, Col, Dropdown, Empty, Layout, Popover, Row, Space } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { Context } from "../..";
import OrderInfo from "../../components/modals/OrderInfo";
import UnloadingInfo from "../../components/modals/UnloadingInfo";
import useMediaQuery from "../../hooks/custom-hooks/useMediaQuery";
import actionService from "../../services/actionService";
import timetableService from "../../services/timetableService";
import uploadingService from "../../services/uploadingService";
import { notificationWarehouse } from "../../utils/helpers";
import { requestWrapper } from "../../utils/requestWrapper";

const { Header } = Layout;

const DefaultHeader = ({ setMenuActive }) => {
  const navigate = useNavigate();
  const { user, notificationStore } = useContext(Context);
  const [userName, setUserName] = useState("");
  const [userPosition, setUserPosition] = useState("");
  const [userState, setUserState] = useState("");
  const [openPopover, setOpenPopover] = useState(false);

  const [isActive, setIsActive] = useState(false);
  const [isActiveUnloading, setIsActiveUnloading] = useState(false);
  const [warehouse, setWarehouse] = useState(null);
  const [data, setData] = useState();

  const isMobileScreen = useMediaQuery("(max-width: 768px)");

  const { updateActions } = actionService.useStorageLogActionList({
    onSuccessful: (data) => {
      notificationStore.setNotification(data);
      // eslint-disable-next-line no-use-before-define
      notificationStore.handleClickNotification = handleClickNotification;
    },
    onError: (error) => {
      notificationWarehouse("error", `Не удалось получить список уведомлений: ${error}`);
    },
  });

  const { saveAction } = actionService.wrapperReadNotification({
    onSuccessful: () => {
      updateActions();
    },
    onError: (error) => {
      notificationWarehouse("error", `Произошла ошибка: ${error}`);
    },
  });

  const [getEventById] = requestWrapper({
    requestFunction: timetableService.getEventById,
    onSuccess: (data) => {
      const mainEvent = data.event;

      setData({
        ...mainEvent.extendedProps,
        date: mainEvent.start,
      });
      setIsActive(true);
    },
    onError: (error) => {
      notificationWarehouse("error", `Не удалось загрузить запись: ${error}`);
    },
  });

  const { getUnloading } = uploadingService.wrapperUnloadingById({
    onSuccessful: (responseData) => {
      setData(responseData);
      setIsActiveUnloading(true);
      setWarehouse(responseData.sklad_use);
    },
    onError: (error) => {
      notificationWarehouse("error", `Не удалось загрузить запись: ${error}`);
    },
  });

  const handleClickNotification = (event) => {
    if ([13, 18].includes(event.storage_action_id)) {
      getUnloading({ id: event.storage_timetable_id });
    }

    if ([1, 3, 5, 11, 12].includes(event.storage_action_id)) {
      getEventById(event.storage_timetable_id);
    }

    if ([4, 6].includes(event.storage_action_id)) {
      const win = window.open(event.notificationLink, "_blank");

      win.focus();
    }

    if ([7, 8].includes(event.storage_action_id)) {
      navigate(`/show_record/${event.storage_timetable_id}`);
    }

    setOpenPopover(false);

    saveAction({
      id: event.id,
      read: true,
      readUserId: user.user.id,
    });
  };

  const readAllNotification = (eventArr) => {
    eventArr.forEach((event) => {
      saveAction({
        id: event.id,
        read: true,
        readUserId: user.user.id,
      });
    });
  };

  const notifications = (
    <Col className="notification-wrapper">
      <div
        style={{
          maxHeight: 220,
          minHeight: 220,
          overflowY: notificationStore.notifications.length > 5 ? "auto" : "hidden",
          margin: "0 0 10px 0",
        }}
      >
        {notificationStore.notifications?.map((el) => {
          let type = "success";

          if ([2, 3].includes(el.storage_action_id)) type = "error";

          return (
            <Alert
              key={el.id}
              style={{ margin: "0 0 5px 0", cursor: "pointer" }}
              onClick={() => handleClickNotification(el)}
              type={type}
              showIcon={true}
              message={el.notificationText}
            />
          );
        })}
      </div>
      {notificationStore.notifications?.length === 0 && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Нет новых уведомлений"} />
      )}
      {notificationStore.notifications?.length > 0 && (
        <Button onClick={() => readAllNotification(notificationStore.notifications)}>Прочитать все</Button>
      )}
    </Col>
  );

  const logoutHandler = () => {
    user.logout();
  };

  const items = [
    {
      label: (
        <div style={{ textAlign: "center" }}>
          <h2 key={userPosition}> {userPosition} </h2>
        </div>
      ),
      key: "item-0",
    },
    {
      label: (
        <div style={{ textAlign: "center", maxHeight: "120px", overflow: "auto" }}>
          <h3 key={userState}>{userState}</h3>
        </div>
      ),
      key: "item-1",
    },
    {
      label: (
        <Button
          icon={<img style={{ margin: "-6px 0" }} src={"/assets/img/icon_exit.svg"} alt={"exit"} height={22} />}
          onClick={logoutHandler}
          block
          danger
        >
          Выход
        </Button>
      ),
      key: "item-2",
    },
  ];

  const updateUserInfo = () => {
    if (user.user) {
      const userInfo = user.user;

      setUserName(userInfo.f + " " + userInfo.i + " " + userInfo.o);
      setUserPosition(userInfo.postName);
      setUserState(userInfo.stateName.join(", "));
    }
  };

  // Task: socket
  const [timer, setTimer] = useState(false);

  useEffect(() => {
    const time = setTimeout(() => {
      setTimer(!timer);
      updateActions();
    }, 10000);

    return () => {
      clearTimeout(time);
    };
  }, [timer]);
  // Task: socket

  useEffect(() => {
    updateUserInfo();
    setTimeout(() => {
      updateActions();
    }, 1000);
  }, []);

  return (
    <>
      <OrderInfo
        active={isActive}
        setActive={setIsActive}
        data={data}
        setData={setData}
        sklad_use={warehouse}
        update={() => {}}
      />
      <UnloadingInfo
        active={isActiveUnloading}
        setActive={setIsActiveUnloading}
        data={data}
        setData={setData}
        storage={warehouse}
      />

      <Header style={{ position: "fixed", zIndex: 100, width: "100%", padding: 0 }}>
        <Row align={"middle"}>
          <Col span={3}>
            <div className="logo" style={{ background: "none" }}>
              <img
                className="img-logo"
                src={"/assets/img/logo.png"}
                alt={"Склад"}
                onClick={() => navigate("/", { replace: true })}
              />
            </div>
          </Col>
          <Col span={20} className="ant-col-info-user">
            <Space size={20} align="center">
              <Button
                icon={<img src="/assets/img/favicon.ico" alt="Перейти в HelpDesk" className="btn-favicon-img" />}
                ghost
                className="btn-favicon"
                onClick={() => {
                  window.open("https://hd.hd-resanta.ru/", "_blank");
                }}
              >
                {!isMobileScreen ? <span className="btn-favicon-text">Перейти в HelpDesk</span> : null}
              </Button>
              <Badge size="default" count={notificationStore.notifications?.length}>
                <Popover
                  placement={"bottom"}
                  trigger={"click"}
                  content={notifications}
                  open={openPopover}
                  onOpenChange={(visible) => setOpenPopover(visible)}
                >
                  <Button icon={<BellOutlined />} className="btn-notification" shape="square" size="large" />
                </Popover>
              </Badge>
              <Dropdown menu={{ items }} trigger={"click"} overlayStyle={{ maxWidth: "400px" }}>
                <Space className="user-name-header">
                  <span className="icon">{<UserOutlined />}</span>
                  {!isMobileScreen ? <span className="user-name">{userName}</span> : null}
                </Space>
              </Dropdown>
              <MenuOutlined
                className="btn-active-menu"
                style={{ color: "white" }}
                onClick={() => setMenuActive((prev) => !prev)}
              />
            </Space>
          </Col>
        </Row>
      </Header>
    </>
  );
};

export default observer(DefaultHeader);
