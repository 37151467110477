import { Button, Checkbox, Input, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../..";
import actionService from "../../services/actionService";
import timetableService from "../../services/timetableService";
import { Div } from "../../utils/Styled";

const SpareParts = ({ data, setData, update, warehouse, startDate, endDate, week }) => {
  const [isContainsSpareParts, setIsContainsSpareParts] = useState(data?.isSpareParts ?? false);
  const [loadingSaveSparePartsComment, setLoadingSaveSparePartsComment] = useState(false);
  const [sparePartsComment, setSparePartsComment] = useState("");

  const { user } = useContext(Context);

  const getEventById = async (timetableId) => {
    timetableService.getEventById(timetableId).then((response) => {
      const mainEvent = response.data.event.extendedProps;

      setData({ ...data, ...mainEvent });
    });
  };

  const { saveTimetable } = timetableService.wrapperStorageTimetableEdit({
    onSuccessful: (data) => {
      notification.success({
        message: data?.message,
      });
      update(warehouse, startDate, endDate);
      getEventById(data.result.id);
      setLoadingSaveSparePartsComment(false);
    },
    onError: (error) => {
      notification.error({
        message: `Произошла ошибка: ${error}`,
      });
      setLoadingSaveSparePartsComment(false);
    },
  });

  const saveSparePartsComment = () => {
    setLoadingSaveSparePartsComment(true);
    saveTimetable({
      id: data.id,
      sparePartsComment: sparePartsComment,
    });
  };

  const { createAction } = actionService.useStorageLogActionCreate({ onSuccessful: () => {}, onError: () => {} });

  const { createTimetable: createTimetableAction } = timetableService.wrapperStorageTimetableCreate({
    onSuccessful: (data) => {
      update(warehouse, startDate, endDate);
      getEventById(data.id);
    },
    onError: () => {},
  });

  const onChangeCheckbox = async (checked) => {
    setIsContainsSpareParts(checked);
    if ((data.status === "tamplate") | (data.status === "template")) {
      const newData = {
        date: data?.startTime,
        event_duration: data.eventDuration,
        inn: data.inn,
        sklad_use: warehouse,
        state_id: data.stateId,
        status: "entryInfo",
        weight: data.weight,
        record_volume: data.record_volume,
        type: "pogrz",
        week: week,
        template: true,
        isSpareParts: checked,
      };

      createTimetableAction({ ...newData }).then((timetable) => {
        createAction({
          id: timetable.id,
          actionId: checked ? 15 : 16,
        });
      });
    } else {
      saveTimetable({
        id: data.id,
        isSpareParts: checked,
        action_id: checked ? 15 : 16,
      });
    }
  };

  useEffect(() => {
    if (data?.isSpareParts) {
      setIsContainsSpareParts(data.isSpareParts);
    }

    setSparePartsComment(data?.sparePartsComment);

    return () => {
      setIsContainsSpareParts(false);
      setLoadingSaveSparePartsComment(false);
      setSparePartsComment("");
    };
  }, [data]);

  return (
    <>
      {user.user.type_id === 48 && ["agreed", "entryInfo", "redact", "tamplate", "template"].includes(data?.status) ? (
        <>
          <Checkbox checked={isContainsSpareParts} onChange={(e) => onChangeCheckbox(e.target.checked)}>
            Погрузка содержит запчасти
          </Checkbox>
          {isContainsSpareParts ? (
            <div style={{ margin: "5px 14px 0 14px" }}>
              <Input.TextArea
                rows={2}
                value={sparePartsComment}
                placeholder={"Комментарий о запчастях..."}
                onChange={(e) => setSparePartsComment(e.target.value)}
                style={{ margin: "0 0 5px 0" }}
              />
              <Button onClick={() => saveSparePartsComment()} loading={loadingSaveSparePartsComment}>
                Сохранить комментарий
              </Button>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {data?.isSpareParts && user.user.type_id !== 48 ? <Div>погрузка содержит запчасти</Div> : ""}
    </>
  );
};

export default SpareParts;
